<template>
    <section>
        <section class="search-container">
            <a-row class="right-action-panel m-t-14">
                <a-button type="primary" class="item" @click="showJobTypeModal(null)">
                    添加作业类型
                </a-button>
            </a-row>
        </section>
        <section class="list-table-container m-t-18">
            <a-table size="small" :columns="columns" :data-source="listmap" :pagination="{total,hideOnSinglePage:true}">
                <a slot="action" slot-scope="text,list">
                    <a class="m-r-10" @click="showJobTypeModal(list)">编辑</a>
                    <!-- <a-popconfirm title="确定删除该职务?" @confirm="deleteItem(list.id)">删除</a-popconfirm> -->
                </a>
            </a-table>
        </section>

         <!-- modal -->
        <a-modal centered width="660px" class="modal-container" :title="JobTypeModal.title" v-model="JobTypeModal.visible" :footer="false" :destroyOnClose="true">
           <a-form :form="form">
               
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="作业类型名称">
                   <a-input placeholder="请输入" v-decorator="['name',{rules:[{required:true,message:'作业类型名称不能为空'}]}]" class="form-input"></a-input>
               </a-form-item>
               
    
               <a-row class="form-btn-group">
                   <a-button class="btn-save" :disabled="submitLoadding" :loading="submitLoadding" @click="submit"><a-icon type="save" v-if="!submitLoadding" />保存</a-button>
                   <a-button class="btn-cancel"  @click="JobTypeModal.visible = false">取消</a-button>
               </a-row>
           </a-form>

        </a-modal>
    </section>
</template>

<script>
const columns = [
    {title:'序号',dataIndex:'key',align:'center',key:'key'},
    {title:'作业类型名称',dataIndex:'name',align:'center',key:'name'},
    {title:'操作',scopedSlots:{customRender: "action"},align:'center'},
]
export default {
    data(){
        return{
            columns,
            total:0,
            listmap:[],
            JobTypeModal:{
                visible:false,
                title:'添加'
            },
            JobTypeId:''
        }
    },
    beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    created(){
        this.querySafeJobTypeList()
    },
    computed:{
        submitLoadding(){
            return this.$store.state.submitLoadding
        }
    },
    methods:{
        showJobTypeModal(list){
            this.JobTypeModal.visible = true;
            this.JobTypeModal.title = list ? '编辑作业类型' : '新增作业类型';
            this.JobTypeId = list ? list.id : ''
            this.JobTypeId && this.backfillForm(list)
        },
        backfillForm(list){
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    name:list.name
                })
            })
        },
        querySafeJobTypeList(){
            this.$api.querySafeJobTypeList().then(res =>{
                if(res.code === 200){
                    this.listmap = (res.data && res.data.records) || []
                    this.listmap.forEach((item,key) =>{
                        item.key = key +1
                    })
                }
            })
        },
        //添加和编辑问题等级时提交表单
        submit(){
            this.form.validateFields((err,formData) =>{
                if(!err){
                    this.$store.commit('SET_SUBMITLOADDING',true)
                    if(this.JobTypeId){
                        formData.id = this.JobTypeId
                    }
                    let apiName = this.JobTypeId ? 'updateSafeJobType' : 'insertSafeJobType'
                    this.$api[apiName](formData).then(res =>{
                        if(res.code === 200){
                            this.JobTypeModal.visible = false;
                            this.querySafeJobTypeList()
                        }
                    })
                }
            })
        },
        //删除
        deleteItem(id){
            this.$api.deleteSafeJobType(id).then(res =>{
                if(res.code === 200){
                    this.querySafeJobTypeList()
                }
            })
        }
    }
}   
</script>