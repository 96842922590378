<template>
    <section>
        <section class="search-container">
            <a-row class="right-action-panel m-t-14">
                <a-button type="primary" class="item" @click="showLevelModal(null)">
                    添加等級
                </a-button>
            </a-row>
        </section>
        <section class="list-table-container m-t-18">
            <a-table size="small" :columns="columns" :data-source="listmap" :pagination="{total,hideOnSinglePage:true}">
                <a-row slot="checked">
                    <a-checkbox :checked="true" />
                </a-row>
                <a slot="action" slot-scope="text,list" v-if="list.id != 1 && list.id != 2 && list.id != 3">
                    <a class="m-r-10" @click="showLevelModal(list)">编辑</a>
                    <a-popconfirm title="确定删除该等级?" @confirm="deleteItem(list.id)">删除</a-popconfirm>
                </a>
            </a-table>
        </section>

         <!-- modal -->
        <a-modal centered width="660px" class="modal-container" :title="levelModal.title" v-model="levelModal.visible" :footer="false" :destroyOnClose="true">
           <a-form :form="form">
               
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="问题等级">
                   <a-input placeholder="请输入" v-decorator="['name',{rules:[{required:true,message:'问题等级不能为空'}]}]" class="form-input"></a-input>
               </a-form-item>
               
    
               <a-row class="form-btn-group">
                   <a-button class="btn-save" :disabled="submitLoadding" :loading="submitLoadding" @click="submit" ><a-icon v-if="!submitLoadding" type="save" />保存</a-button>
                   <a-button class="btn-cancel"  @click="levelModal.visible = false">取消</a-button>
               </a-row>
           </a-form>

        </a-modal>
    </section>
</template>

<script>
const columns = [
    {title:'序号',dataIndex:'key',align:'center',key:'key'},
    {title:'问题等级',dataIndex:'name',align:'center',key:'name'},
    {title:'默认选项',dataIndex:'checked',align:'center',key:'checked',scopedSlots:{customRender: "checked"}},
    {title:'操作',scopedSlots:{customRender: "action"},align:'center'},
]
export default {
    data(){
        return{
            columns,
            total:0,
            listmap:[],
            levelModal:{
                visible:false,
                title:'添加'
            },
            levelId:''
        }
    },
    beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    created(){
        this.querySafeLevelList()
    },
    computed:{
        submitLoadding(){
            return this.$store.state.submitLoadding
        }
    },
    methods:{
        showLevelModal(list){
            this.levelModal.visible = true;
            this.levelModal.title = list ? '编辑问题等级' : '新增问题等级';
            this.levelId = list ? list.id : ''
            this.levelId && this.backfillForm(list)
        },
        backfillForm(list){
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    name:list.name
                })
            })
        },
        querySafeLevelList(){
            this.$api.querySafeLevelList().then(res =>{
                if(res.code === 200){
                    this.listmap = (res.data && res.data.records) || []
                   
                    this.listmap.forEach((item,key) =>{
                        item.key = key +1
                    })
                }
            })
        },
        //添加和编辑问题等级时提交表单
        submit(){
            this.form.validateFields((err,formData) =>{
                if(!err){
                    this.$store.commit('SET_SUBMITLOADDING',true)
                    if(this.levelId){
                        formData.id = this.levelId
                    }
                    let apiName = this.levelId ? 'updateSafeLevel' : 'insertSafeLevel'
                    this.$api[apiName](formData).then(res =>{
                        if(res.code === 200){
                            this.levelModal.visible = false;
                            this.querySafeLevelList()
                        }
                    })
                }
            })
        },
        //删除
        deleteItem(id){
            this.$api.deleteSafeLevel(id).then(res =>{
                if(res.code === 200){
                    this.querySafeLevelList()
                }
            })
        }
    }
}
</script>