<template>
    <section>
        <!-- <section class="search-container">
            <a-row class="right-action-panel m-t-14">
                <a-button class="item" @click="showEducationTypeModal(null)">
                    添加培训类型
                </a-button>
            </a-row>
        </section> -->
        <section class="list-table-container m-t-18">
            <a-table size="small" :columns="columns" :data-source="listmap" :pagination="{total,hideOnSinglePage:true}">
                <!-- <a slot="action" slot-scope="text,list">
                    <a class="m-r-10" @click="showEducationTypeModal(list)">编辑</a>
                    <a-popconfirm title="确定删除培训类型?" @confirm="deleteItem(list.id)">删除</a-popconfirm>
                </a> -->
            </a-table>
        </section>

         <!-- modal -->
        <a-modal centered width="660px" class="modal-container" :title="educationTypeModal.title" v-model="educationTypeModal.visible" :footer="false" :destroyOnClose="true">
           <a-form :form="form">
               
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="职务名称">
                   <a-input placeholder="请输入" v-decorator="['name',{rules:[{required:true,message:'职务名称不能为空'}]}]" class="form-input"></a-input>
               </a-form-item>
               
    
               <a-row class="form-btn-group">
                   <a-button class="btn-save"  @click="submit"><a-icon type="save" />保存</a-button>
                   <a-button class="btn-cancel"  @click="educationTypeModal.visible = false">取消</a-button>
               </a-row>
           </a-form>

        </a-modal>
    </section>
</template>

<script>
const columns = [
    {title:'序号',dataIndex:'key',align:'center',key:'key'},
    {title:'职务名称',dataIndex:'name',align:'center',key:'name'},
    
    {title:'操作',scopedSlots:{customRender: "action"},align:'center'},
]
export default {
    data(){
        return{
            columns,
            total:0,
            listmap:[],
            educationTypeModal:{
                visible:false,
                title:'添加'
            },
            educationTypeId:''
        }
    },
    beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    created(){
        this.querySafeEducationTypeList()
    },
    methods:{
        showEducationTypeModal(list){
            this.educationTypeModal.visible = true;
            this.educationTypeModal.title = list ? '编辑问题等级' : '新增问题等级';
            this.educationTypeId = list ? list.id : ''
            this.educationTypeId && this.backfillForm(list)
        },
        backfillForm(list){
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    name:list.name
                })
            })
        },
        querySafeEducationTypeList(){
            this.$api.querySafeEducationTypeList().then(res =>{
                if(res.code === 200){
                    this.listmap = (res.data && res.data.records) || []
                    this.listmap.forEach((item,key) =>{
                        item.key = key +1
                    })
                }
            })
        },
        //添加和编辑问题等级时提交表单
        submit(){
            this.form.validateFields((err,formData) =>{
                if(!err){
                    if(this.educationTypeId){
                        formData.id = this.educationTypeId
                    }
                    let apiName = this.educationTypeId ? 'updateSafeEducationType' : 'insertSafeEducationType'
                    this.$api[apiName](formData).then(res =>{
                        if(res.code === 200){
                            this.educationTypeModal.visible = false;
                            this.querySafeEducationTypeList()
                        }
                    })
                }
            })
        },
        //删除
        deleteItem(id){
            this.$api.deleteSafeEducationType(id).then(res =>{
                if(res.code === 200){
                    this.querySafeEducationTypeList()
                }
            })
        }
    }
}   
</script>