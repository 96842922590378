<template>
    <section>
        <section class="search-container">
            <a-row class="right-action-panel m-t-14">
                <a-button type="primary" class="item" @click="showPositionModal(null)">
                    添加职务
                </a-button>
            </a-row>
        </section>
        <section class="list-table-container m-t-18">
            <a-table size="small" :columns="columns" :data-source="listmap" :pagination="{total,hideOnSinglePage:true}">
                <a slot="action" slot-scope="text,list">
                    <!-- <a class="m-r-10" @click="showPositionModal(list)">编辑</a> -->
                    <a-popconfirm title="确定删除该职务?" @confirm="deleteItem(list.id)">删除</a-popconfirm>
                </a>
            </a-table>
        </section>

         <!-- modal -->
        <a-modal centered width="660px" class="modal-container" :title="positionModal.title" v-model="positionModal.visible" :footer="false" :destroyOnClose="true">
           <a-form :form="form">
               <!-- <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="单位类型">
                   <a-select placeholder="请选择" v-decorator="['positionId',{rules:[{required:false,message:'单位类型不能为空'}]}]">
                       <a-select-option v-for="(item,key) in jobsMap" :key="key" :value="item.id">{{item.companyTypeName}}</a-select-option>
                   </a-select>
               </a-form-item> -->
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="职务名称">
                   <a-select placeholder="请选择" v-decorator="['positionId',{rules:[{required:true,message:'职务名称不能为空'}]}]">
                       <a-select-option v-for="(item,key) in jobsMap" :key="key" :value="item.id">{{item.name}}</a-select-option>
                   </a-select>
               </a-form-item>
               
               <a-row class="form-btn-group">
                   <a-button class="btn-save" :disabled="submitLoadding" :loading="submitLoadding" @click="submit"><a-icon v-if="!submitLoadding" type="save" />保存</a-button>
                   <a-button class="btn-cancel"  @click="positionModal.visible = false">取消</a-button>
               </a-row>
           </a-form>

        </a-modal>
    </section>
</template>

<script>
const columns = [
    {title:'序号',dataIndex:'key',align:'center',key:'key'},
    {title:'职务名称',dataIndex:'name',align:'center',key:'name'},
    
    {title:'操作',scopedSlots:{customRender: "action"},align:'center'},
]
export default {
    data(){
        return{
            columns,
            total:0,
            listmap:[],
            positionModal:{
                visible:false,
                title:'添加'
            },
            jobsMap:[],
            companyTypeMap:[],
            positionId:''
        }
    },
    beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    created(){
        this.queryTypeDropDownBox()
        this.querySafeOccupationList()
        this.queryJobsDropDownBox()
    },
    computed:{
        submitLoadding(){
            return this.$store.state.submitLoadding
        }
    },
    methods:{
        //职务列表下拉
        queryJobsDropDownBox(){
            this.$api.queryJobsDropDownBox({type:2}).then(res =>{
                if(res.code === 200){
                    this.jobsMap = res.data || []
                }
            })
        },
        //单位类型下拉
        queryTypeDropDownBox(){
            this.$api.queryTypeDropDownBox().then(res =>{
                if(res.code === 200){
                    this.companyTypeMap = res.data || []
                }
            })
        },
        showPositionModal(list){
            this.positionModal.visible = true;
            this.positionId = list ? list.id : ''
            this.positionId && this.backfillForm(list)
        },
        backfillForm(list){
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    positionId:list.remarks
                })
            })
        },
        querySafeOccupationList(){
            this.$api.querySafeOccupationList().then(res =>{
                if(res.code === 200){
                    this.listmap = (res.data && res.data.records) || []
                    this.listmap.forEach((item,key) =>{
                        item.key = key +1
                    })
                }
            })
        },
        //添加和编辑问题等级时提交表单
        submit(){
            this.form.validateFields((err,formData) =>{
                if(!err){
                    this.$store.commit('SET_SUBMITLOADDING',true)
                    let params = {
                        name:this.jobsMap.filter(item => item.id === formData.positionId)[0].name,
                        remarks:formData.positionId
                    }
                    if(this.positionId){
                        params.id = this.positionId
                    }

                    let apiName = this.positionId ? 'updateSafeOccupation' : 'insertSafeOccupation'
                    this.$api[apiName](params).then(res =>{
                        if(res.code === 200){
                            this.positionModal.visible = false;
                            this.querySafeOccupationList()
                        }
                    })
                }
            })
        },
        //删除
        deleteItem(id){
            this.$api.deleteSafeOccupation(id).then(res =>{
                if(res.code === 200){
                    this.querySafeOccupationList()
                }
            })
        }
    }
}   
</script>