<template>
    <section>
        <section class="nav-container m-t-18">
            <a @click="navActiveKey = item.key" :class="item.key == navActiveKey ? 'active' : ''" v-for="item in navMap" :key="item.key">{{item.name}}</a>
        </section>

        <section>
            <level v-if="navActiveKey === 1" />
            <position v-if="navActiveKey === 2" />
            <job-type v-if="navActiveKey === 3" />
            <education-type v-if="navActiveKey === 4" />
        </section>
    </section>
</template>

<script>
import Level from './components/level'
import Position from './components/position'
import JobType from './components/jobType'
import EducationType from './components/educationType'
export default {
    data(){
        return{
            navMap:[
                {name:'问题等级',key:1},
                {name:'监管职务',key:2},
                {name:'作业类型',key:3},
                {name:'培训类型',key:4},
            ],
            navActiveKey:1
        }
    },
    components:{Level,Position,JobType,EducationType}
}
</script>